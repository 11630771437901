"use client"
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sectionhead from './Sectionhead';
import Mensajeinput from './Mensajeinput';
import Boton from './Boton';
import useSessionStorageState from 'use-session-storage-state'


const Comunicaciones = () => {
    const logged = useSessionStorageState('logged')[0];
    const usuario = useSessionStorageState('usuario')[0];
    const grupo = useSessionStorageState('grupo')[0];
    const admin = useSessionStorageState('admin')[0];
    const nomgrupo = useSessionStorageState('nomgrupo')[0];
    const [mailsgrupo, setMailsgrupo] = useState('')
    const [data, setData] = useState(null);
    const [ok, setOk] = useState(true)
    const [fotoscomms, setFotosComms] = useState('')
    const [foto,setFoto] = useState('')

    const getEmails = async (logged, grupo) => {
        try {
            const response = await axios.post("https://familia.caminantes.cl/emails/", {
                method: "POST",
                mode: "no-cors",  // Change the mode to CORS  
                params: {
                    logged: logged,
                    codgrupo: grupo
                }
            }
            );
            if (response.data.data) {
                let datos = response.data.data;
                let listTo = [];
                datos.forEach(element => {
                    listTo.push(element.MAILUSUARIO)
                });
                setMailsgrupo(listTo.toString())
            }
        } catch (error) {
            console.error(error);
        }
    };
    function envComunicacion(texto, cancelar, file, link) {
        const postComunicacion = async () => {
            if (texto !== undefined) {
                try {
                    const response = await axios.post("https://familia.caminantes.cl/ingcom", {
                        method: "POST",
                        mode: "no-cors",  // Change the mode to CORS
                        params: {
                            comunicacion: texto + '. Atentamente, ' + usuario.NOMUSUARIO,
                            grupo: grupo,
                            link: link
                        },
                    })
                    if (file !== undefined) {
                        let nombrefoto = response.data.data.insertId;
                        handleUploadFotoComm(nombrefoto, file);
                    }

                } catch (error) {
                    console.error(error);
                }
            }
            setOk(!ok)
            enviaMail(mailsgrupo,texto,nomgrupo, usuario.NOMUSUARIO,file)
        };
        postComunicacion()
    }
    const enviaMail = async (mailsgrupo, mensaje, destinatario, remitente, file) => {
            const formData = new FormData();
            formData.append('foto', foto);
            formData.append('mailsgrupo', mailsgrupo);
            formData.append('mensaje', mensaje);
            formData.append('destinatario', destinatario);
            formData.append('remitente', remitente);
            formData.append('dir', '../escuela/images/comms/');
            await fetch('https://php.caminantes.cl/mailcomm.php', {
                method: 'POST',
                mode: 'no-cors',
                body: formData,
            });
    }
    function delComunicacion(id, texto) {
        if (window.confirm('Borrar esta comunicación: "' + texto + '" ?' + id)) {
            const delCom = async () => {
                if (id !== null) {
                    try {
                        await axios.delete("https://familia.caminantes.cl/delcom/'" + id + "'", {
                            method: "POST",
                            mode: "no-cors",  // Change the mode to CORS
                            params: {
                                id: id,
                            },
                        });
                    } catch (error) {
                        console.error(error);
                    }
                    setOk(!ok);
                }
            };
            delCom();
        }
    }
    function recibeMensaje(mensaje) {
        envComunicacion(mensaje.msg, mensaje.cancelar, mensaje.file, mensaje.link)
    }
    const handleUploadFotoComm = async (nomFoto, image) => {
        if (image !== '') {
            let nomfile = nomFoto + '-' + new Date().getHours() + new Date().getMinutes()
            setFoto(nomfile)
            const formData = new FormData();
            formData.append('file', image);
            formData.append('text', nomfile);
            formData.append('dir', '../escuela/images/comms/');
            await fetch('https://php.caminantes.cl/uploadFoto.php', {
                method: 'POST',
                mode: 'no-cors',
                body: formData,
            });
            fetchFotosComms()
        }
    };
    const fetchFotosComms = async () => {
        var formData = new FormData();
        if (logged) {
            formData.append('logged', logged)
            axios.post('https://php.caminantes.cl/listaFotosComms.php', formData, {
                method: 'POST',
                mode: 'no-cors',
            })
                .then(function (response) {
                    setFotosComms(response.data)
                    setOk(!ok)
                });
        }

    }
    useEffect(() => {
        fetchFotosComms()
        // eslint-disable-next-line
    }, [grupo])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://familia.caminantes.cl/comunica/", {
                    method: "GET",
                    mode: "no-cors",  // Change the mode to CORS  
                }
                );
                let datos = response.data.data;
                datos = datos.filter((f) => { return (f.GRUPO === grupo) })
                setData(datos)
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
        getEmails(logged, grupo)
        // eslint-disable-next-line
    }, [ok, grupo]);
    if (logged === true) {
        return (
            <div className='pt-1'>
                <Sectionhead titulo={"Libreta de Comunicaciones - " + Date().toString().substring(0, 10)} />
                {admin === true ?
                    <div className='pt-1 '>
                        <div id="caja texto comunicación" className='flex-col'>
                            <Mensajeinput
                                plantilla={"Comunicación para " + nomgrupo}
                                funcion={recibeMensaje}
                                borrar={true}
                                confoto={true}
                                condoc={true}
                                cancelar={true}
                                conlink={true}
                            />
                        </div>
                    </div>
                    : ''
                }
                {data ? data.map((comm, index) =>
                (
                    <div key={index} className=' flex flex-col basis-0.5 pt-1 pb-1' >
                        <div className='rounded-lg border-4  bg-yellow-500'>
                            <div className='font-bold px-1 pt-1 '>
                                {new Date(comm.FECCOMUNICACION).toLocaleDateString()}
                                {comm.GRUPO === 1 ? ' Camino de la Montaña ' :
                                    comm.GRUPO === 0 ?
                                        " Comunidad Escuela " :
                                        " Camino del Bosque "}
                            </div>
                            <div className='px-1'>{comm.TXTCOMUNICACION}</div>
                            {comm.LINK !== '' ?
                                <div className='bg-sky-300 text-lg font-semibold'>
                                    {'Link adjunto: '}
                                    <a target='_new' href={comm.LINK} className='px-1 pt-2'>{comm.LINK}</a>
                                </div>
                                : ''
                            }
                        </div>
                        <div id='fotoadjunta' className=' pt-1  mx-auto'>
                            {fotoscomms && fotoscomms.filter((f) => { return f.slice(0, f.search("-")) === comm.CODCOMUNICACION.toString() })[0]
                                ?
                                <img src={'https://escuela.caminantes.cl/images/comms/' + fotoscomms.filter((f) => { return f.slice(0, f.search("-")) === comm.CODCOMUNICACION.toString() })[0]}
                                    className=' rounded-lg w-screen'
                                    alt={comm.CODCOMUNICACION}>
                                </img>
                                :
                                <></>
                            }
                        </div>
                        <div id='botonborrar' className='pt-1 pb-1' hidden={!admin}>
                            <Boton textBoton="Borrar"
                                funcion={() => delComunicacion(comm.CODCOMUNICACION, comm.TXTCOMUNICACION)} />
                        </div>
                    </div>
                )) : ''
                }
            </div>
        )
    }
}
export default Comunicaciones;
