import React, { useState, useEffect, useRef } from 'react'
import { startOfWeek } from '../App';
import axios from 'axios';
import Sectionhead from './Sectionhead';
import useSessionStorageState from 'use-session-storage-state'

export default function Semana() {
  const logged = useSessionStorageState('logged')[0];
  const grupo = useSessionStorageState('grupo')[0];
  const nomgrupo = useSessionStorageState('nomgrupo')[0];
  const admin = useSessionStorageState('admin')[0];
  const usuario = useSessionStorageState('usuario')[0];
  const semana = startOfWeek();
  const [selectedFile, setSelectedFile] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const fileInputRef = useRef(null);
  const [cal, setCal] = useState([]);
  const [refresh, setRefresh] = useState('');
  const [mailsgrupo, setMailsgrupo] = useState('')

  const getEmails = async (logged, grupo) => {
    try {
      const response = await axios.post("https://familia.caminantes.cl/emails/", {
        method: "POST",
        mode: "no-cors",  // Change the mode to CORS  
        params: {
          logged: logged,
          codgrupo: grupo
        }
      }
      );
      if (response.data.data) {
        let datos = response.data.data;
        let listTo = [];
        datos.forEach(element => {
          listTo.push(element.MAILUSUARIO)
        });
        setMailsgrupo(listTo.toString())
      }
    } catch (error) {
      console.error(error);
    }
  };
  const enviaMail = (input) => {
    axios.post('https://php.caminantes.cl/mailCal.php', input)
  }
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedExtension = ['.jpg', '.png', '.jpeg', '.jfif'];
      const selectedFileExtension = file.name.split('.').pop().toLowerCase();
      if (allowedExtension.includes('.' + selectedFileExtension)) {
        setSelectedFile(file);
        setValidationError(null);
      }
      else {
        setSelectedFile(null);
        setValidationError('Por favor seleccione archivos con extensión .jpg, .jpeg, .jfif o .png ');
        fileInputRef.current.value = '';
      }
    }
  };
  const handleUpload = async () => {
    getEmails(logged, grupo)
    if (selectedFile) {
      let foto = (grupo + "-" + Date.now()+'.jpg')
      console.log(foto)
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('grupo', foto);
      const response = await fetch('https://php.caminantes.cl/uploadCal.php', {
        method: 'POST',
        body: formData
      });
      fileInputRef.current.value = '';
      response.status === 200 ?
        alert("Calendario agregado OK") :
        alert('La imagen no pudo ser agregada')
      let input = {
        remitente: "Educadora " + nomgrupo, mensaje: 'Calendario Semanal. Atentamente, ' + usuario.NOMUSUARIO
        , emaildestinatario: mailsgrupo, destinatario: nomgrupo, foto: foto
      }
      enviaMail(input)
      setRefresh(!refresh);
    }
    else {
      setValidationError('Selecciona una imagen antes de enviar');
    }
  };

  function borrarCal(img) {
    const delCal = async () => {
      axios.get('https://php.caminantes.cl/delCal.php?foto=' + img)

    }
    delCal()
    setRefresh(!refresh);
  }
  useEffect(() => {
    if (logged) {
      const getCal = async () => {
        axios.get('https://php.caminantes.cl/listaCal.php/').then((response) => {
          response.data.pop();
          response.data.pop();
          setCal(response.data.filter((a) => { return a.toString().substr(0, 1) === grupo.toString() }).slice(0, 1)[0])
        })

      }
      getCal();
    }
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [logged, grupo, refresh]);
  if (logged && cal !== '') {
    let imgSemana = 'https://escuela.caminantes.cl/images/calendario/' + cal
    return (
      <div className='pt-1'>
        <div >
          <Sectionhead titulo={'Ruta Semanal ' + semana} />
        </div>
        <div className=''>
          <img src={imgSemana} alt={semana} className='border-red-800 lg:px-20 pt-1 lg:py-2 mx-auto rounded-lg'></img>
        </div>
        <div id='uploadcal' hidden={!admin} className=' text-center pt-1'>
          <button className=" h-8 w-20 rounded-lg  bg-violet-600  text-white mx-auto"
            onClick={() => { borrarCal(cal) }}>Borrar
          </button>
          <div className="grid pt-1   justify-items-center justify-self-auto">
            <input className="justify-self-auto bg-violet-600"
              type="file" ref={fileInputRef} onChange={handleFileChange} />
          </div>
          <div className=" pt-1 grid justify-items-center">
            <button className=" px-1 h-8 w-auto rounded-lg  bg-violet-600 justify-self-auto text-white text-nowrap"
              onClick={handleUpload}>
              {'Agregar Calendario'}
            </button>
          </div>
          <p>{validationError}</p>
        </div>
      </div>
    )
  }

}

